<template>
  <div class="dashboard-container">
    <div class="header-container flex flex-between">
      <div class="left-box"></div>
      <div class="right-box flex flex-start">
        <div class="clock">
          <div class="hour-hand"></div>
          <div class="minute-hand"></div>
        </div>
        <div class="date">{{ headDate }}</div>
      </div>
    </div>
    <div class="header-btn-container flex flex-between">
      <div class="left" @click="clickBackBtn">
        <img src="@/assets/dashboard/class/btn_back_hover_old.png" alt="">
        <span class="text">返回</span>
      </div>
      <div class="right flex flex-start">
        <img src="@/assets/dashboard/class/header_search_icon.png" alt="" class="search-icon">
        <div class="search-input">
          <input type="text" placeholder="可以输入姓名、学号进行搜索" v-model="searchWord">
        </div>
        <div class="search-btn" @click="clickSearchBtn">
          搜索
        </div>
      </div>
    </div>
    <div class="big-container flex flex-start">
      <span class="container-title">学生学情数据</span>
      <div class="left-container">
        <div class="student-list flex flex-dr flex-center">
          <div :class="studentInfo.id===item.id?'li active':'li'" v-for="item in studentList"
               @click="clickStudent(item)">{{ item.name }}
          </div>
        </div>
      </div>
      <div class="right-container flex flex-dr flex-center">
        <div class="overview-box flex flex-start">
          <div class="left flex flex-start">
            <div class="name-box flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/overview_left_sex_female.png" alt="" v-show="studentInfo.sex==='女'">
              <img src="@/assets/dashboard/class/overview_left_sex_male.png" alt="" v-show="studentInfo.sex==='男'">
              <span class="name">{{ studentInfo.name }}</span>
            </div>
            <div class="info-box">
              <div class="li flex flex-start">
                <span class="title">班级：</span>
                <span class="text">{{ clazzName }}</span>
              </div>
              <div class="li flex flex-start">
                <span class="title">学号：</span>
                <span class="text">{{ studentInfo.account }}</span>
              </div>
              <div class="li flex flex-start">
                <span class="title">最近学习时间：</span>
                <span
                    class="text">{{
                    studentOverview.lastSubmitTime ? date_format(studentOverview.lastSubmitTime, "yyyy-MM-dd HH:mm:ss") : ""
                  }}</span>
              </div>
            </div>
          </div>
          <div class="right flex flex-between">
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/课程完成量.png" alt="">
              <span class="title">课程完成量</span>
              <span class="number">{{ studentOverview.completeNum }}</span>
            </div>
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/课程完成率.png" alt="">
              <span class="title">课程完成率</span>
              <span class="number">{{
                  studentOverview.scheduleNum === 0 ? 0 : (studentOverview.completeNum / studentOverview.scheduleNum).toFixed(2) * 100
                }}%</span>
            </div>
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/平均得分.png" alt="">
              <span class="title">平均得分</span>
              <span class="number">{{ studentOverview.completeAvgScore.toFixed(0) }}</span>
            </div>
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/学习最高分.png" alt="">
              <span class="title">学习最高分</span>
              <span class="number">{{ studentOverview.completeMaxScore.toFixed(0) }}</span>
            </div>
          </div>
        </div>
        <div class="course-box flex flex-dr flex-center">
          <span class="box-title">课程完成情况</span>
          <div class="course-list flex flex-dr flex-center">
            <div class="li flex flex-start" v-for="item in experimentDataList">
              <div class="li-left flex flex-between">
                <div class="avatar-box">
                  <img :src="item.experiment.icon"/>
                </div>
                <div class="numbers">
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">完成人次</span>
                      <span class="number">{{ item.data.completeNum }}/{{ item.data.scheduleNum }}</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeNum/item.data.scheduleNum)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">平均得分</span>
                      <span class="number">{{ item.data.completeAvgScore.toFixed(0) }}分</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show" :style="'width:'+300*(item.data.completeAvgScore/100)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">完成率</span>
                      <span class="number">{{ item.data.completeRate }}%</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeRate/100)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">平均耗时</span>
                      <span class="number">{{ (item.data.completeAvgUsedTime / 60).toFixed(0) }}分钟</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeAvgUsedTime /3600 )+'px'"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="li-right flex flex-dr flex-center">
                <div class="line line1 flex flex-start">
                  <div class="line-left">
                    <span class="text">班级综合排名</span>
                  </div>
                  <div class="line-right flex flex-start">
                    <span class="t1">第</span>
                    <div class="box">
                      {{ item.rankObject["classRank"] ? item.rankObject["classRank"] : "大于100" }}
                    </div>
                    <span class="t2">名</span>
                  </div>
                </div>
                <div class="line line2 flex flex-start">
                  <div class="line-left">
                    <span class="text">校内综合排名</span>
                  </div>
                  <div class="line-right flex flex-start">
                    <span class="t1">第</span>
                    <div class="box">
                      {{ item.rankObject["schoolRank"] ? item.rankObject["schoolRank"] : "大于100" }}
                    </div>
                    <span class="t2">名</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-box">技术服务：成都智云鸿道信息技术有限公司</div>
    <!--全屏按钮弹出框-->
    <div class="my-dialog-container" v-show="showFullDialog">
      <div class="mask"></div>
      <div class="dialog-box" style="background-color: transparent;width: 100%;top:50%">
        <div class="flex flex-center">
          <el-button id="fullScreenBtn" type="danger" size="">全屏查看</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {StudentModel} from "@/model/exp/StudentModel";
import {StatisticModel} from "@/model/exp/StatisticModel";
import {date_format} from "@/utils/common";
import elDragDialog from "@/directive/el-drag-dialog";

export default {
  name: "v1Class",
  directives: {
    elDragDialog
  },
  data() {
    return {
      date_format: date_format,
      headDate: "",
      headDateTimeId: "",
      schoolId: this.$route.query["schoolId"],
      clazzId: this.$route.query["clazzId"],
      clazzName: this.$route.query["clazzName"],
      experimentList: [],
      studentList: [],
      studentInfo: {},
      experimentDataList: [],
      studentOverview: {
        completeNum: 0,
        scheduleNum: 0,
        completeAvgScore: 0,
        completeMaxScore: 0,
      },
      searchWord: "",
      showFullDialog: false,
    }
  },
  async mounted() {
    // 顶部每秒日期更新
    this.headDateTimeId = setInterval(() => {
      this.formatHeadDate()
    }, 1000)
    await this.getDashboardClazzExperiments()
    await this.getExperimentData()
    this.getStudentList()

    // 如果不是全屏状态就弹出全屏按钮
    if (!document.fullscreenElement) {
      this.showFullDialog = true
    }
    let $this = this;
    document.getElementById("fullScreenBtn").onclick = function () {
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setTimeout(() => {
        $this.showFullDialog = false
      }, 1000)
    }
    window.onresize = function () {
      if ($this.showFullDialog === false) {
        $this.showFullDialog = true
      }
    }
  },
  beforeDestroy() {
    //  组件销毁时清除定时器
    clearInterval(this.headDateTimeId)
  },
  methods: {
    // 点击搜索按钮
    clickSearchBtn() {
      this.getStudentList()
    },
    // 点击返回按钮
    clickBackBtn() {
      this.$router.push("/dashboard/v1/index")
    },
    // 点击某个学生
    async clickStudent(student) {
      this.studentInfo = student
      // 获取这个学生的实验综合统计信息
      this.studentOverview = await StatisticModel.getDashboardClazzOneStudentOverview(student.id)
      // 遍历实验，获取每个实验这个学生的班级和学校排名情况
      for (let i = 0; i < this.experimentDataList.length; i++) {
        let experimentId = this.experimentDataList[i]["experiment"].id;
        let rankObject = await StatisticModel.getOneStudentOneExperimentRankOfClass(student.id, student.administrationClazzId, experimentId, this.schoolId)
        this.$set(this.experimentDataList[i], "rankObject", rankObject)
      }
    },
    // 获取学生列表
    async getStudentList() {
      let query = {
        clazzid: this.clazzId
      }
      query = Object.assign(query, {
        search: this.searchWord
      });
      [this.studentList,] = await StudentModel.getList(1, 500, query)
      // 默认点击第一个学生
      this.clickStudent(this.studentList[0])
    },
    // 获取本班级安排的所有实验id列表
    async getDashboardClazzExperiments() {
      this.experimentList = await StatisticModel.getDashboardClazzExperiments(this.clazzId)
    },
    // 获取某个实验的数据
    async getExperimentData() {
      for (let i = 0; i < this.experimentList.length; i++) {
        let experiment = this.experimentList[i]
        let experimentId = experiment.id
        let result = await StatisticModel.dashboard_index_1(this.schoolId, this.clazzId, null, null, experimentId)
        if (result) {
          if (result.scheduleNum === 0) {
            result.completeRate = 0;
          } else {
            result.completeRate = (result.completeNum / result.scheduleNum).toFixed(2) * 100;
          }
          // 获取每个实验的实验用时数据
          let scoreResult = await StatisticModel.dashboard_index_2(this.schoolId, this.clazzId, null, null, experimentId)
          result.completeAvgUsedTime = scoreResult.completeAvgUsedTime // 给单个课程赋值
          // 加入实验列表
          this.experimentDataList.push({
            experiment: experiment,
            data: result,
            rankObject: {}
          })
        }
      }
    },
    // 格式化顶部日期
    formatHeadDate() {
      let date = new Date();
      const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const weekday = weekdays[date.getDay()];
      const hour = date.getHours();
      const minute = date.getMinutes();
      // 使用模板字符串拼接出格式化后的日期时间字符串
      this.headDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${weekday} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

div.dashboard-container {
  background-image: url('../../assets/dashboard/background.png');
  background-position: center 85%;
  height: 100vh;
  width: 100%;
  font-family: MicrosoftYaHei;
  color: #2EB8FF;
  position: relative;
}

// 顶部
div.header-container {
  width: 100%;
  height: 113px;
  background-image: url('../../assets/dashboard/head_title.png');
  background-position: center;

  div.right-box {
    margin-bottom: 40px;
    margin-right: 20px;

    .clock {
      width: 25px;
      height: 25px;
      border: 2px solid #2EB8FF;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    .hour-hand {
      width: 2px;
      height: 6px;
      background-color: #2EB8FF;
      position: absolute;
      top: 25%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(0deg);
    }

    .minute-hand {
      width: 2px;
      height: 7px;
      background-color: #2EB8FF;
      position: absolute;
      top: 19%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(140deg);
    }

    .date {
      font-size: 18px;
      color: #2EB8FF;
    }
  }
}

// 顶部按钮框
div.header-btn-container {
  margin-top: -40px;

  div.left {
    margin-left: 20px;
    width: 204px;
    height: 62px;
    position: relative;
    cursor: pointer;

    img {
      width: 204px;
      height: 62px;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }

    span.text {
      width: 100px;
      position: absolute;
      left: 100px;
      top: 18px;
      z-index: 1;
      font-size: 25px;
      color: #fff;
    }
  }

  div.right {
    margin-right: 20px;

    img.search-icon {
      width: 39px;
      height: 39px;
    }

    div.search-input {
      width: 542px;
      height: 43px;
      background-image: url('../../assets/dashboard/class/header_search_input_background.png');
      background-position: center;

      input {
        width: 542px;
        height: 43px;
        padding-left: 30px;
        color: #fff;
      }
    }

    div.search-btn {
      cursor: pointer;
      width: 141px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-image: url('../../assets/dashboard/class/header_search_btn_background.png');
      background-position: center;
      letter-spacing: 2px;

      &:hover {
        background-image: url('../../assets/dashboard/class/header_search_btn_active_background.png');
        font-weight: bold;
      }
    }
  }
}

// 大内容框
div.big-container {
  width: 1842px;
  height: 887px;
  margin: 0 auto;
  position: relative;
  background-image: url('../../assets/dashboard/class/cotent_background.png');
  background-position: center;

  span.container-title {
    position: absolute;
    left: 865px;
    top: 0px;
    font-weight: bold;
    font-size: 20px;
    color: #75fbfd;
    letter-spacing: 1px;
  }

  div.left-container {
    width: 225px;
    height: 800px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background-color: #32747c;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #75fbfd;
    }


    div.student-list {
      margin-left: 10px;

      div.li {
        cursor: pointer;
        width: 180px;
        height: 37px;
        line-height: 37px;
        margin-bottom: 15px;
        background-color: #102e40;
        color: #70f1f3;
        text-align: center;

        &:hover, &.active {
          background-color: #235567;
        }
      }
    }
  }

  div.right-container {
    width: 1539px;
    height: 813px;
    margin-left: 35px;
    background-image: url('../../assets/dashboard/class/content_right_background.png');
    background-position: center;

    div.overview-box {
      div.left {
        width: 612px;
        height: 232px;
        background-image: url('../../assets/dashboard/class/overview_left_background.png');
        background-position: center;

        .name-box {
          height: 150px;

          img {
            width: 158px;
            height: 152px;
          }

          span.name {
            margin-top: -40px;
            color: #fff;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 25px;
          }
        }

        .info-box {
          width: 435px;
          height: 182px;
          background-image: url('../../assets/dashboard/class/学生信息底框.png');
          background-position: center;
          padding-top: 20px;
          padding-left: 45px;

          .li {
            margin-bottom: 30px;
          }

          .title {
            color: #fff;
            font-size: 20px;
          }

          .text {
            max-width: 300px;
            font-size: 20px;
          }
        }
      }

      div.right {
        width: 750px;
        margin-left: 30px;

        img {
          width: 133px;
          height: 114px;
        }

        .title {
          margin-top: 25px;
          font-size: 24px;
          font-family: SimHei;
          color: #10E4E5;
        }

        .number {
          margin-top: 15px;
          font-size: 30px;
          color: #ebeef2;
        }
      }
    }

    div.course-box {
      margin-top: 45px;
      width: 1417px;
      height: 497px;
      background-image: url('../../assets/dashboard/class/课程完成情况底板.png');
      background-position: center;
      position: relative;

      .box-title {
        position: absolute;
        left: 52px;
        top: 15px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .course-list {
        margin-top: 90px;
        height: 400px;
        overflow-y: scroll;
        width: 1400px;
        padding-left: 35px;

        &::-webkit-scrollbar {
          background-color: #32747c;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #75fbfd;
        }

        .li {
          width: 1330px;
          border: 2px solid #3f8ed6;
          margin-bottom: 15px;
          padding-right: 30px;
        }

        .li-left {
          width: 490px;
          padding: 10px 15px;
          margin-right: 200px;
          margin-left: 50px;

          .avatar-box {
            width: 153px;
            height: 146px;
            padding: 15px 14px 15px 14px;
            background-image: url('../../assets/dashboard/pic_kuang.png');
            background-position: center;
            margin-right: 30px;

            img {
              width: 125px;
              height: 120px;
            }
          }

          .numbers {
            .number-li {
              width: 300px;
              height: 40px;
              color: #bacfdd;
              font-size: 16px;

              .title {
                margin-bottom: 2px;
              }

              .number-bar {
                position: relative;

                .number-bar-bg {
                  background-color: #1f222f;
                  width: 300px;
                  height: 15px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: 0;
                }

                .number-bar-show {
                  background-color: #4993ce;
                  width: 100px;
                  height: 15px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: 1;
                }
              }
            }
          }
        }

        .li-right {
          .line {
            margin-bottom: 15px;

            .line-left {
              margin-right: 40px;

              span.text {
                color: #5bc8e1;
                font-size: 25px;
              }
            }

            .line-right {
              font-size: 23px;
              color: #65daef;

              .t1 {
                margin-right: 12px;
              }

              .t2 {
                margin-left: 12px;
              }

              .box {
                width: 165px;
                height: 57px;
                line-height: 57px;
                text-align: center;

                color: #6be7fc;
                font-size: 30px;
                background-image: url('../../assets/dashboard/class/排名边框.png');
                background-position: center;
              }
            }
          }

          .line1 {
            .line-left {
              width: 257px;
              height: 49px;
              text-align: center;
              padding-left: 40px;
              line-height: 49px;
              background-image: url('../../assets/dashboard/class/班级综合排名.png');
              background-position: center;
            }
          }

          .line2 {
            .line-left {
              width: 257px;
              height: 49px;
              text-align: center;
              line-height: 49px;
              padding-left: 40px;
              background-image: url('../../assets/dashboard/class/校内综合排名.png');
              background-position: center;
            }
          }
        }
      }
    }
  }
}

div.company-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 350px;
  height: 17px;
  font-size: 18px;
  font-family: SimHei;
  color: #50A2B0;
  line-height: 28px;
  background: radial-gradient(NaNdeg, #209CFF 0%, #68E0CF 0%);
  -webkit-background-clip: text;
}
</style>
